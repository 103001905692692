import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../../../hooks";
import OrderComplete from "../../OrderComplete";
import { TransactionDetail } from "../../../Transactions";
import CancelTransactionModal from "../../../Modals/CancelTransactionModal";
import { NavigationButton, PrimaryButton, SmallButton } from "../../../Buttons";
import ErrorMessage from "../../../Common/ErrorMessage";
import Label from "../../../Label";
import SelectAccountDropdown from "../../../Common/SelectAccountDropdown";
import FormInputField from "../../../FormInputField";
import { TransferIcon } from "../../../Icons";
import PriceSummarySkeleton from "../../../Skeleton/PriceSummarySkeleton";
import PriceSummary from "../../PriceSummary";
import EmptyCryptoTransactions from "../../../Empty/EmptyCryptoTransactions";
// import Modal from "../../../Common/Modal";
// import HangTightLoading from "../../../Common/HangTightLoading";
import { FormatBalance, GetCurrenySymbol } from "../../../../helper";
import FormTextAreaField from "../../../FormTextAreaField";
import useGetPrice from "../../../../hooks/usePrice";
import { useCryptoTransaction } from "../../../../hooks/useCryptoTransaction";
import { setActiveAccount } from "../../../../redux/actions/home/ActiveAccountAction";
import LoadingSpinner from "../../../Common/LoadingSpinner";

// handle the error messages and add an api to get the max and min count for currencies from bitpace.
const BitpaceBuy = () => {
  const coinAmount = useForm("");
  const fiatAmount = useForm("");
  const remarksInput = useForm("");
  const [isLoading, setIsLoading] = useState(false);
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state.client.clientInfo);
  const fiatAccounts = clientInfo.accounts.filter(
    (account) =>
      account.currency_code === "EUR" || account.currency_code === "USD"
  );
  const cryptoAccount = clientInfo.crypto_accounts.filter(
    (account) => account.currency_code !== "GED"
  );

  const [selectedFiatAccount, setSelectedFiatAccount] = useState(
    fiatAccounts[0]
  );
  const [currencyAmountField, setCurrencyAmountField] = useState("");

  const {
    isPriceLoading,
    showPriceSummary,
    priceSummary,
    error,
    handleGetPrice,
    setError,
    setShowPriceSummary,
  } = useGetPrice({
    selectedFiatAccount,
    activeAccount,
    coinAmount,
    fiatAmount,
    orderType: "BUY",
    currencyAmountField,
  });

  const { handleTransaction, handleRepeatTransaction, order } =
    useCryptoTransaction({
      selectedFiatAccount,
      activeAccount,
      coinAmount,
      fiatAmount,
      remarksInput,
      priceSummary,
      setError,
      orderType: "BUY",
      resetAmounts: () => {
        coinAmount.reset();
        fiatAmount.reset();
        setShowPriceSummary(false);
      },
      setIsLoading,
    });

  const handleCoinAmountChange = (e) => {
    // coinAmount.onChange(e);
    coinAmount.setValue(parseFloat(e.target.value));
    if (e.target.value) {
      fiatAmount.reset();
      setCurrencyAmountField("amount");
    }
    setShowPriceSummary(false);
  };

  const handleFiatAmountChange = (e) => {
    // fiatAmount.onChange(e);
    fiatAmount.setValue(parseFloat(e.target.value));
    if (e.target.value) {
      coinAmount.reset();
      setCurrencyAmountField("fiat_amount");
    }
    setShowPriceSummary(false);
  };

  const resetAmounts = () => {
    coinAmount.reset();
    fiatAmount.reset();
    setShowPriceSummary(false);
  };

  const canSubmit = coinAmount.value !== "" && fiatAmount.value !== "";

  return (
    <>
      {order.status === "COMPLETED" || order.status === "PENDING" ? (
        <div className="max-w-[992px] mx-auto">
          <div className="flex flex-col items-center justify-center md:p-6">
            <div className="md:w-[568px] px-2">
              <OrderComplete
                message=" will be added to
              the below account details."
                repeatTransaction={handleRepeatTransaction}
                coinAmount={order.cryptocurrency_amount}
              >
                <TransactionDetail label="Order Id" value={order.order_id} />
                <TransactionDetail label="Type" value={order.type} />
                <TransactionDetail label="Status" value={order.status} />
                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail
                  label="Order Amount"
                  value={order.order_amount}
                />
                <TransactionDetail
                  label="Cryptocurrency Amount"
                  value={order.cryptocurrency_amount}
                />
                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail label="Currency" value={order.currency} />
                <TransactionDetail
                  label="Cryptocurrency"
                  value={order.cryptocurrency}
                />
                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail
                  label="Blockchain Tx Id"
                  value={order.blockchain_tx_id || "Not Available"}
                />
                <TransactionDetail
                  label="Blockchain Address"
                  value={order.blockchain_address || "Not Available"}
                />
              </OrderComplete>
            </div>
          </div>
        </div>
      ) : (
        <main className="pb-4">
          {fiatAccounts.length ? (
            <div className="max-w-4xl mx-auto p-6 max-[360px]:p-4 bg-dropdown-popup-bg rounded-3xl shadow-box-shadow">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold mb-4 text-body-text">
                  Buy Crypto
                </h1>
                <CancelTransactionModal
                  contextLabel="Cancel Buy"
                  navigateTo="/crypto"
                >
                  <SmallButton text={"Cancel"} cancel={true} />
                </CancelTransactionModal>
              </div>
              {error && (
                <ErrorMessage message={error} onClose={() => setError("")} />
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <Label
                    className="block text-base font-medium text-body-text mb-2"
                    htmlFor="fiatAccount"
                    text="Fiat Account"
                  />
                  <div className="space-y-2">
                    <SelectAccountDropdown
                      title={"Select fiat account"}
                      accounts={fiatAccounts}
                      slectedAccount={selectedFiatAccount}
                      setAccount={(account) => {
                        setSelectedFiatAccount(account);
                        resetAmounts();
                      }}
                    />
                    <FormInputField
                      id="account_balance"
                      label="Fiat Account Balance"
                      type="text"
                      value={FormatBalance(selectedFiatAccount?.balance) || "--"}
                      currency={GetCurrenySymbol(
                        selectedFiatAccount.currency_code,
                        "13",
                        "13"
                      )}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div>
                  <Label
                    className="block text-base font-medium text-body-text mb-2"
                    htmlFor="cryptoAccount"
                    text="Crypto Account"
                  />
                  <div className="space-y-2">
                    <SelectAccountDropdown
                      title={"Select crypto account"}
                      setAccount={(account) => {
                        dispatch(setActiveAccount(account));
                        resetAmounts();
                      }}
                      accounts={cryptoAccount}
                      slectedAccount={activeAccount}
                    />
                    <FormInputField
                      id="crypto_account_balance"
                      label="Crypto Account Balance"
                      type="text"
                      value={FormatBalance(activeAccount?.balance, true) || "--"}
                      currency={GetCurrenySymbol(
                        activeAccount.currency_code,
                        "13",
                        "13"
                      )}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="grid sm:grid-cols-12 mt-3">
                <div className="col-span-6">
                  <FormTextAreaField
                    label="Remarks"
                    name="remarks"
                    id="remarks"
                    rows="5"
                    cols="33"
                    placeholder="Please provide relvant remarks for the transaction."
                    {...remarksInput}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between gap-4 mt-3">
                <div className="flex-1">
                  <FormInputField
                    label={`Crypto ${
                      activeAccount?.currency_code || ""
                    } coins to Buy`}
                    name="amount"
                    id="amount"
                    type="number"
                    required={true}
                    {...coinAmount}
                    onChange={handleCoinAmountChange}
                  />
                </div>
                <TransferIcon className={"h-6 w-6"} />
                <div className="flex-1">
                  <FormInputField
                    label={`${
                      selectedFiatAccount.currency_code || "Fiat"
                    } amount to Buy Crypto`}
                    name="fiat_amount"
                    id="fiat_amount"
                    type="number"
                    required={true}
                    {...fiatAmount}
                    onChange={handleFiatAmountChange}
                  />
                </div>
              </div>

              <div className="space-y-4 mt-4">
                {isPriceLoading ? (
                  <PriceSummarySkeleton />
                ) : (
                  showPriceSummary && (
                    <PriceSummary
                      rate={priceSummary.rate}
                      commissionRate={priceSummary.commissionRate}
                      commissionFee={priceSummary.commissionFee}
                      approxFiatAmount={priceSummary.approxFiatAmount}
                    />
                  )
                )}
              </div>

              <div className="grid grid-cols-2 gap-4 sm:grid-cols-6 mt-3">
                <NavigationButton onClick={handleGetPrice} text="Get Price" />
                <PrimaryButton
                  text="Submit"
                  onClick={handleTransaction}
                  disabled={!canSubmit || !showPriceSummary}
                />
              </div>
            </div>
          ) : (
            <div className="max-w-4xl md:mx-auto space-y-20 mx-2 min-h-[80vh] p-4 md:p-6 bg-dropdown-popup-bg rounded-3xl shadow-box-shadow">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold mb-4 text-body-text">
                  Buy Crypto
                </h1>
                <CancelTransactionModal
                  contextLabel="Cancel Buy Transaction"
                  navigateTo="/crypto"
                >
                  <SmallButton text={"Cancel Buy Transaction"} cancel={true} />
                </CancelTransactionModal>
              </div>
              <div className="flex items-center justify-center">
                <EmptyCryptoTransactions />
              </div>
            </div>
          )}
        </main>
      )}
      {isLoading && (
        <LoadingSpinner />
        // <Modal
        //   isOpen={isLoading}
        //   loading={isLoading}
        //   className="bg-popup-bg w-full max-w-[432px] py-4 overflow-scroll hide-scrollbar shadow-box-shadow rounded-3xl mx-auto"
        // >
        //   <HangTightLoading message="Processing your transaction, please wait..." />

        // </Modal>
      )}
    </>
  );
};

export default BitpaceBuy;
