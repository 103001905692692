import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CryptoBuy } from "../../redux/actions/CryptoTransaction/Buy";
import { CryptoSell } from "../../redux/actions/CryptoTransaction/Sell";
import { GetValidationErrors } from "../../helper";

export const useCryptoTransaction = ({
  selectedFiatAccount,
  activeAccount,
  coinAmount,
  remarksInput,
  priceSummary,
  setError,
  orderType,
  resetAmounts,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = useState({
    blockchain_address: "",
    blockchain_tx_id: "",
    cryptocurrency: "",
    cryptocurrency_amount: "",
    currency: "",
    order_amount: "",
    order_id: "",
    status: "",
    type: "",
  });

  const handleTransaction = async () => {
    if (!selectedFiatAccount || !activeAccount) {
      setError("Please select both fiat and crypto accounts.");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const res = await dispatch(
        orderType === "BUY"
          ? CryptoBuy({
              receiver_account_id: activeAccount.id,
              debit_account_id: selectedFiatAccount.id,
              amount: coinAmount.value,
              total_amount: priceSummary.total_amount,
              cryptocurrency_amount: priceSummary.cryptocurrency_amount,
              order_type: "BUY",
              token: priceSummary.token,
              remarks: remarksInput.value,
            })
          : CryptoSell({
              receiver_account_id: selectedFiatAccount.id,
              debit_account_id: activeAccount.id,
              amount: coinAmount.value,
              total_amount: priceSummary.total_amount,
              cryptocurrency_amount: priceSummary.cryptocurrency_amount,
              order_type: "SELL",
              token: priceSummary.token,
              remarks: remarksInput.value,
            })
      );

      if (res.status === "DECLINED") {
        if (
          res.data &&
          res.data.status === "DECLINED" &&
          res.data.message ===
            "Quoted price has changed. Please get a new quote to proceed."
        ) {
          setError(
            res.data.message +
              "! Please click Get Price button to get new price."
          );
        } else {
          setError(res.message);
        }
        setIsLoading(false);
        return;
      }

      if (res.status === "Approved" || res.status === "Pending") {
        const parsedData = JSON.parse(res.data);
        setOrder({
          blockchain_address: parsedData.data.blockchain_address,
          blockchain_tx_id: parsedData.data.blockchain_tx_id,
          cryptocurrency: parsedData.data.cryptocurrency,
          cryptocurrency_amount: parsedData.data.cryptocurrency_amount,
          currency: parsedData.data.currency,
          order_amount: parsedData.data.order_amount,
          order_id: parsedData.data.order_id,
          status: parsedData.data.status,
          type: parsedData.data.type,
        });
      } else {
        if (res.errors) {
          setError(GetValidationErrors(res.errors));
        } else {
          setError(res.message);
        }
      }
    } catch (error) {
      setError("Unexpected error occurred. Please try again later.");
    }
    setIsLoading(false);
  };

  const handleRepeatTransaction = () => {
    setOrder({
      blockchain_address: "",
      blockchain_tx_id: "",
      cryptocurrency: "",
      cryptocurrency_amount: "",
      currency: "",
      order_amount: "",
      order_id: "",
      status: "",
      type: "",
    });
    remarksInput.reset();
    resetAmounts();
    setError("");
    navigate(orderType === "BUY" ? "/crypto-buy" : "/crypto-sell");
  };

  return { handleTransaction, handleRepeatTransaction, order };
};
