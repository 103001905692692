import commonService from "../../../../services/Common/service";
import { AuthActionTypes, CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

export const ChangeUserPassword = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const res = await commonService.post("/user/change-password", data);
    dispatch({
      type: AuthActionTypes.CHANGE_PASSWORD,
      payload: res.data.token,
    });

    const updatedUser = {
      ...user,
      token: res.data.token,
    };

    localStorage.setItem("user", JSON.stringify(updatedUser));

    return res.data;
  } catch (error) {
    const message = error.response?.data?.message || error.message;

    ResponseErrorHandling(error.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return error.response?.data || { message: "An error occurred." };
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
