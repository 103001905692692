import React, { useEffect, useState } from "react";
import Modal from "../../Common/Modal";
import { useDispatch } from "react-redux";
import EnterOTP from "../../Common/EnterOTP";
// import HangTightLoading from "../../Common/HangTightLoading";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { verifyLoginOtp } from "../../../redux/actions/register/otp";
import { useNavigate } from "react-router-dom";
import { AuthActionTypes } from "../../../redux/action-types";
import { getOwnCompany } from "../../../redux/actions/lookups/getOwnCompany";

const LoginOTPModal = ({
  isLoading,
  setisLoading,
  isModalOpen,
  setIsModalOpen,
  email,
  reSendOTP,
  isButtonDisabled,
  setIsButtonDisabled,
  Error,
  setError,
}) => {
  // const [Error, setError] = useState("");
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [counter, setCounter] = useState(60);
  const navigate = useNavigate();

  const handleOnCloseModal = () => {
    setIsModalOpen(false);
    setError("");
    setOtp(["", "", "", "", "", ""]);
  };

  const handleOTP = async () => {
    setIsModalOpen(false);
    setisLoading(true);

    try {
      const data = {
        email: email,
        code: otp.join(""),
      };

      const res = await dispatch(verifyLoginOtp(data));
      if (res.success === true) {
        localStorage.setItem("user", JSON.stringify(res));
        dispatch({
          type: AuthActionTypes.LOGIN,
          payload: res,
        });
        dispatch(getOwnCompany());
        navigate("/");
      } else if (res.status === "RateLimited") {
        setIsButtonDisabled(true);
        const duration = 60;
        setError(`Too many attempts. Please try again in ${duration} seconds.`);
        startCountdown(duration);
      } else {
        setError(res.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setisLoading(false);
      setIsModalOpen(true);
    }
  };

  const handleResendCode = async () => {
    setResendDisabled(true);
    setisLoading(true);
    setIsModalOpen(false);
    await reSendOTP();
    setisLoading(false);
    setIsModalOpen(true);
    setCounter(60);
  };

  const startCountdown = (duration) => {
    let timeLeft = duration;
    const timerInterval = setInterval(() => {
      timeLeft -= 1;

      setError(() => `Please try again in ${timeLeft} seconds`);

      if (timeLeft <= 0) {
        clearInterval(timerInterval);
        setError("");
        setIsButtonDisabled(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (isModalOpen) {
      setCounter(60);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isLoading && resendDisabled && isModalOpen) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            setResendDisabled(false);
            clearInterval(timer);
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isLoading, resendDisabled, isModalOpen]);

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={isLoading ? false : true}
        backButtonAction={() => {}}
        headerTitle={"Enter OTP"}
        className="w-[432px] overflow-scroll hide-scrollbar bg-popup-bg shadow-xl rounded-3xl py-3"
        loading={isLoading}
      >
        <div className="px-6 pt-2">
          {!isLoading ? (
            <EnterOTP
              handleOTP={handleOTP}
              otp={otp}
              setOtp={setOtp}
              error={Error}
              setError={setError}
              resendDisabled={resendDisabled}
              counter={counter}
              handleResendCode={handleResendCode}
              isButtonDisabled={isButtonDisabled}
            />
          ) : (
            // isLoading && <HangTightLoading message={"Please wait..."} />
            ""
          )}
        </div>
      </Modal>
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default LoginOTPModal;
