import React, { useEffect, useState } from "react";
import CancelTransactionModal from "../../../components/Modals/CancelTransactionModal";
import { PrimaryButton, SmallButton } from "../../../components/Buttons";
import { FormInputField, Label } from "../../../components";
import SelectAccountDropdown from "../../../components/Common/SelectAccountDropdown";
import {
  FormatBalance,
  FormatTRDate,
  GetCurrenySymbol,
  GetValidationErrors,
} from "../../../helper";
import FormTextAreaField from "../../../components/FormTextAreaField";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteDocument, useForm } from "../../../hooks";
import {
  CryptoWithdraw,
  UpdateCryptoWithdraw,
} from "../../../redux/actions/CryptoTransaction/Withdraw";
import TransactionOTPModal from "../../../components/Modals/TransactionsOTPModal";
import { verifyTROTP } from "../../../redux/actions/TRrequest/OTPVerification";
import ErrorMessage from "../../../components/Common/ErrorMessage";
import ConfirmWithdrawCrypto from "../../../components/Modals/ConfirmWithdrawCrypto";
import { getTransactionDetails } from "../../../redux/actions/Transaction/TransactionHistory";
import { useLocation } from "react-router-dom";
import OrderComplete from "../../../components/Crypto/OrderComplete";
import { TransactionDetail } from "../../../components/Transactions";
import { setActiveAccount } from "../../../redux/actions/home/ActiveAccountAction";
import PreviousDocs from "../../../components/Common/PreviousDocs";
import { setPageTitle } from "../../../helper/metaDataHelper";
import ViewSupportingDocument from "../../../components/Common/ViewSupportingDocument";
import MultipleFileUpload from "../../../components/MultipleFileUpload";

// handle the error messages and add an api to get the max and min count for currencies from bitpace.
const Withdraw = () => {
  const dispatch = useDispatch();
  const amount = useForm("", "crypto_amount");
  const remarksInput = useForm("");
  const walletAddress = useForm("");
  const transactionDate = FormatTRDate(new Date(), "DDMMYYYY");
  const [supportingDocument, setSupportingDocument] = useState("");
  const [files, setFiles] = useState([]);
  const { documents, setDocuments, handleDeleteDocument } = useDeleteDocument(
    []
  );
  const [confirmModal, setConfirmModal] = useState(false);

  const { activeAccount } = useSelector((state) => state.activeAccount);
  const [isLoading, setIsLoading] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [trn_id, setTrnId] = useState("");
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState({
    status: "",
    account: "",
    amount: "",
    transactionDate: "",
    remarks: "",
    supportingDocument: "",
    walletAddress: "",
  });

  const cryptoAccount = useSelector(
    (state) => state.client.clientInfo.crypto_accounts
  );

  const { state } = useLocation();

  useEffect(() => {
    setPageTitle("Crypto Withdraw");
  }, []);

  useEffect(() => {
    if (state) {
      // get transaction details
      const fetchTransactionDetails = async () => {
        const data = {
          trn_id: state.transaction.id,
          transaction_nature: "Withdraw",
        };
        setIsLoading(true);
        const res = await dispatch(getTransactionDetails(data));
        if (res.success) {
          amount.setValue(res.data.crypto_amount);
          remarksInput.setValue(res.data.remarks);
          walletAddress.setValue(res.data.to_wallet_address);

          const crypto = cryptoAccount.find(
            (account) => account.id === parseInt(res.data.account_id)
          );

          dispatch(setActiveAccount(crypto));

          if (res.data.docs && res.data.docs.length > 0) {
            setDocuments(res.data.docs);
          }
        }
        setIsLoading(false);
      };
      fetchTransactionDetails();
    }
  }, [state, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleWithdraw = async () => {
    try {
      setConfirmModal(false);
      setIsOTPModalOpen(true);
      setIsLoading(true);
      const data = new FormData();
      data.append("account_id", activeAccount.id);
      data.append("amount", amount.value);
      data.append("remarks", remarksInput.value);
      data.append("to_wallet_address", walletAddress.value);
      data.append("transaction_date", transactionDate);

      if (supportingDocument) {
        Object.keys(supportingDocument).forEach((key) => {
          supportingDocument[key].forEach((file) => {
            data.append("supporting_documents[]", file);
          });
        });
      }

      let res;
      if (state) {
        data.append("trn_id", state.transaction.id);
        res = await dispatch(UpdateCryptoWithdraw(data));
      } else {
        res = await dispatch(CryptoWithdraw(data));
      }
      setIsLoading(false);

      if (res.success) {
        setIsOTPModalOpen(true);
        setTrnId(res.data.trn_id);
      } else if (!res.success) {
        setIsOTPModalOpen(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);

        if (res.errors) {
          setIsError(GetValidationErrors(res.errors));
        } else {
          setIsError(res.message === "Exception" ? res.error : res.message);
        }
      }
    } catch (error) {
      setIsError(error.message);
      console.error("error", error);
      setIsLoading(false);
    }
  };

  const validateWithdraw = () => {
    let hasError = false;
    if (amount.validate()) {
      hasError = true;
      amount.setError("Please enter a valid amount");
    }

    if (
      amount.value < activeAccount.min_amount ||
      amount.value > activeAccount.max_amount
    ) {
      hasError = true;
      setIsError(
        `Amount should be between ${activeAccount.min_amount} and ${activeAccount.max_amount}`
      );
      amount.setError(true);
    }

    if (!remarksInput.value) {
      hasError = true;
      remarksInput.setError("Please enter a valid remarks");
    }
    if (!walletAddress.value) {
      hasError = true;
      walletAddress.setError("Please enter a wallet address");
    }
    return hasError;
  };

  const handleViewTransaction = () => {
    setIsOTPModalOpen(false);
    setOrder({
      status: "COMPLETED",
      account: activeAccount,
      amount: amount.value,
      transactionDate: transactionDate,
      remarks: remarksInput.value,
      walletAddress: walletAddress.value,
    });
  };

  const repeatTransaction = () => {
    setOrder({
      status: "",
    });
    setIsOTPModalOpen(false);
    amount.reset();
    remarksInput.reset();
    walletAddress.reset();
    setSupportingDocument("");
    setFiles([]);
  };

  const handleFileChange = (name, selectedFiles) => {
    setSupportingDocument((prevFiles) => {
      const updatedFiles = { ...prevFiles, [name]: selectedFiles };
      if (!selectedFiles.length) {
        delete updatedFiles[name];
        return "";
      }
      return updatedFiles;
    });
    if (isError) {
      setIsError([]);
    }
  };

  const canSubmit =
    remarksInput.value !== "" &&
    amount.value !== "" &&
    walletAddress.value !== "";

  return (
    <>
      {order.status === "COMPLETED" ? (
        <div className="max-w-[992px] mx-auto">
          <div className="flex flex-col items-center justify-center md:p-6">
            <div className="md:w-[568px] w-full px-2">
              <OrderComplete
                message=" will be transferred to the account details provided below."
                repeatTransaction={repeatTransaction}
                coinAmount={order.amount + " " + order.account.currency_code}
              >
                <TransactionDetail
                  label="Transaction type"
                  value={"Withdraw"}
                />
                <TransactionDetail label="Status" value={"Pending"} />
                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail
                  label="from"
                  value={`${order.account.title}`}
                />
                <TransactionDetail
                  label="To Wallte Address"
                  value={order.walletAddress}
                />

                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail
                  label="Crypto currency amount"
                  value={`${order.amount} ${order.account.currency_code}`}
                />
                <TransactionDetail label="Remarks" value={order.remarks} />
                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail
                  label="Transaction date"
                  value={order.transactionDate}
                />
                {supportingDocument && (
                  <TransactionDetail
                    label="Supporting documents"
                    value={
                      <ViewSupportingDocument
                        supportingDocument={supportingDocument}
                      />
                    }
                  />
                )}
              </OrderComplete>
            </div>
          </div>
        </div>
      ) : (
        <main className="pb-4">
          <div className="max-w-4xl mx-auto p-6 max-[360px]:p-4 bg-dropdown-popup-bg rounded-3xl shadow-box-shadow">
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-bold mb-4 text-body-text">
                Withdraw Crypto
              </h1>
              <CancelTransactionModal
                contextLabel="Cancel Withdraw"
                navigateTo="/crypto"
              >
                <SmallButton text={"Cancel"} cancel={true} />
              </CancelTransactionModal>
            </div>
            {isError && (
              <ErrorMessage
                message={isError}
                onClose={() => setIsError(false)}
              />
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label
                  className="block text-base font-medium text-body-text mb-2"
                  htmlFor="cryptoAccount"
                  text="From Crypto Account "
                />
                <SelectAccountDropdown
                  title={"Select crypto account"}
                  setAccount={(account) => {
                    dispatch(setActiveAccount(account));
                    amount.reset();
                    setIsError("");
                  }}
                  accounts={cryptoAccount}
                  slectedAccount={activeAccount}
                />
                <FormInputField
                  label={`${
                    activeAccount?.currency_code || ""
                  } Amount to Withdraw`}
                  name="amount"
                  id="amount"
                  type="number"
                  placeholder={`Enter amount in ${activeAccount.currency_code}`}
                  required={true}
                  onChange={(e) => {
                    amount.setValue(parseFloat(e.target.value));
                  }}
                  {...amount}
                  currency={GetCurrenySymbol(
                    activeAccount.currency_code,
                    "13",
                    "13"
                  )}
                />
                {activeAccount.min_amount && activeAccount.max_amount && (
                  <p className="text-xs text-body-text mx-2 my-1">
                    {`Limits: (min: ${activeAccount.min_amount} , max: ${activeAccount.max_amount})`}
                  </p>
                )}
              </div>
              <div className="md:mt-8">
                <FormInputField
                  id="account_balance"
                  label="Account Balance"
                  type="text"
                  value={FormatBalance(activeAccount?.balance, true) || "--"}
                  currency={GetCurrenySymbol(
                    activeAccount.currency_code,
                    "13",
                    "13"
                  )}
                  readOnly={true}
                />
                <div className="my-3">
                  <FormInputField
                    label="Transaction Date"
                    name="transaction_date"
                    id="transaction_date"
                    type="text"
                    value={transactionDate}
                    onChange={() => {}}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
              <div className="col-span-1 md:col-span-1">
                <FormTextAreaField
                  label="Remarks"
                  name="remarks"
                  id="remarks"
                  rows="5"
                  cols="33"
                  placeholder="Please provide relevant remarks for the transaction."
                  {...remarksInput}
                />
              </div>
              <div className="col-span-1 md:col-span-1">
                <FormInputField
                  label="To Wallet Address"
                  name="to_wallet_address"
                  id="to_wallet_address"
                  type="text"
                  placeholder="Please provide the Wallet Address for Crypto Deposit."
                  {...walletAddress}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
              <div>
                <MultipleFileUpload
                  name="supporting_documents"
                  id="supporting_documents"
                  title={"Supporting document"}
                  onChange={handleFileChange}
                  isMultiple={true}
                  maxCount={5}
                  mandatory={true}
                  files={files}
                  setFiles={setFiles}
                />
              </div>

              <PreviousDocs
                documents={documents}
                handleDeleteDocument={handleDeleteDocument}
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-6 mt-3">
              <ConfirmWithdrawCrypto
                wallteAddress={walletAddress.value}
                isModalOpen={confirmModal}
                setIsModalOpen={(open) => {
                  if (validateWithdraw()) return;
                  setConfirmModal(open);
                }}
                handleWithDraw={handleWithdraw}
              >
                <PrimaryButton text="Submit" disabled={!canSubmit} />
              </ConfirmWithdrawCrypto>
            </div>
          </div>
        </main>
      )}

      {isOTPModalOpen && (
        <TransactionOTPModal
          isLoading={isLoading}
          setisLoading={setIsLoading}
          TrnId={trn_id}
          setTrnId={setTrnId}
          isModalOpen={isOTPModalOpen}
          setIsModalOpen={setIsOTPModalOpen}
          handleTransaction={handleWithdraw}
          reduxaction={verifyTROTP}
          navigateUrl={"/crypto"}
          handleViewTransaction={handleViewTransaction}
        />
      )}
    </>
  );
};

export default Withdraw;
